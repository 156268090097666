var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"intro"},[_c('div',{staticClass:"title"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"btns",on:{"click":_vm.goAbout}},[_c('a',{staticClass:"btn1"},[_vm._v("MORE+")])])]),_vm._m(3)]),_c('div',{staticClass:"Tag"},_vm._l((_vm.superiority),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(item.title.replace(/[^\d]/g,' ')))]),_c('span',[_vm._v(_vm._s(item.title.replace(/^\d*/g,'')))])]),_c('p',[_vm._v(_vm._s(item.text))])])}),0)]),_c('Footer'),_c('div',{staticClass:"menu"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"menu-item",on:{"click":()=>{_vm.isShow=true}}},[_c('i',{staticClass:"iconfont icon-erweima"})]),_vm._m(6)]),(_vm.isShow)?_c('div',{staticClass:"ewm",on:{"click":()=>{_vm.isShow=false}}},[_vm._m(7)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel slide",staticStyle:{"margin-top":"80px"},attrs:{"id":"carouselExample"}},[_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('img',{staticClass:"d-block w-100",attrs:{"src":require("../../static/carousel/1.jpg"),"alt":"..."}})]),_c('div',{staticClass:"carousel-item"},[_c('img',{staticClass:"d-block w-100",attrs:{"src":require("../../static/carousel/2.jpg"),"alt":"..."}})]),_c('div',{staticClass:"carousel-item"},[_c('img',{staticClass:"d-block w-100",attrs:{"src":require("../../static/carousel/3.jpg"),"alt":"..."}})])]),_c('button',{staticClass:"carousel-control-prev",attrs:{"type":"button","data-bs-target":"#carouselExample","data-bs-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"visually-hidden"},[_vm._v("Previous")])]),_c('button',{staticClass:"carousel-control-next",attrs:{"type":"button","data-bs-target":"#carouselExample","data-bs-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"visually-hidden"},[_vm._v("Next")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-title"},[_c('div',{staticClass:"E-title"},[_vm._v("COMPANY PROFILE")]),_c('div',{staticClass:"C-title"},[_vm._v("公司简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('p',[_vm._v(" 我们是一家专注于企业数字化转型的科技公司，致力于为企业提供\"从订单到回款\"的一站式IT协同解决方案。通过创新的数字化平台，我们帮助企业打通部门壁垒，实现跨层级高效协同，构建统一的数字化工作生态。 "),_c('br'),_vm._v("【核心价值】 "),_c('ul',[_c('li',[_vm._v("• 打造全流程数字化闭环：从订单管理到账款回收，实现业务流程全覆盖")]),_c('li',[_vm._v("• 构建一体化协同平台：整合企业内外部资源，实现信息实时共享与高效协作")]),_c('li',[_vm._v("• 推动管理数字化转型：将管理制度转化为标准化流程，通过IT系统落地执行")])]),_vm._v(" 【服务优势】 "),_c('ul',[_c('li',[_vm._v("• 提升运营效率：优化业务流程，缩短决策链条")]),_c('li',[_vm._v("• 降低运营成本：减少沟通成本，提高资源利用率")]),_c('li',[_vm._v("• 增强客户满意度：提升服务响应速度，优化客户体验")]),_c('li',[_vm._v("• 实现数据驱动：建立统一数据平台，支持智能决策")])]),_vm._v(" 【使命愿景】"),_c('br'),_vm._v(" 我们以\"让企业协同更智能\"为使命，通过创新的IT解决方案，助力企业实现管理升级和数字化转型，推动企业向智能化、高效化方向发展。 关注我们，获取最新数字化转型解决方案和行业实践案例，开启企业智能化升级之旅！ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../static/983d3eaf73325f9ff4d571bf2f1b70c0.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item"},[_c('i',{staticClass:"iconfont icon-youjian"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item"},[_c('i',{staticClass:"iconfont icon-xinxi"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item"},[_c('i',{staticClass:"iconfont icon-shangjiantou"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"img1"},[_c('img',{attrs:{"src":require("../../static/erweima.jpg"),"alt":""}}),_c('div',[_vm._v("打开手机扫描二维码")])]),_c('div',[_c('i',{staticClass:"iconfont icon-quxiao"})])])
}]

export { render, staticRenderFns }